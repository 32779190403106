import { EventLoaderEvents } from "@otto-ec/global-resources/event-loader";
import { TrackingTopics } from "../types/Tracking";
import { TilelistTopics } from "../types/Tilelist";
import { GlobalPatternTopics } from "../types/GlobalPattern";
import { TileTopics } from "../types/Tile";
import { HistoryTopics } from "../types/BrowserHistory";
import { WishlistTopics } from "../types/Wishlist";
import { eventQBus as eventQBusRaw } from "@otto-ec/global-resources/event-q-bus";
import { AssetTopics } from "../types/Assets";

export type Topics = TileTopics &
  TilelistTopics &
  GlobalPatternTopics &
  TrackingTopics &
  EventLoaderEvents &
  HistoryTopics &
  WishlistTopics &
  AssetTopics;

export const eventQBus = eventQBusRaw.with<Topics>();
